import React from "react";
import HeaderSection from "./HeaderSection";

const BlogsAndProjects = () => {
    return ( 
        <div className="App">
            <HeaderSection />
        </div>
     );
}
 
export default BlogsAndProjects;