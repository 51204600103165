import React from "react";
import Testimonial from "../SubComponents/Testimonial";

const Testimonials = () => {
    return ( 
        <section className="flex font-poppins md:px-24 px-4 md:py-24 py-10 flex-col items-center bg-darkblue-500">
            <p className="font-bold lg:text-4xl md:text-3xl text-xl text-white mb-10">Hear what our customers have to say about us!</p>
            <div className="flex overflow-x-auto w-full">
                <Testimonial />
                <Testimonial />
                <Testimonial />
                <Testimonial />
            </div>
            
        </section>
     );
}
 
export default Testimonials;